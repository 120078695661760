import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { hooks } from 'botframework-webchat-component';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import useHideScrollBarStyles from '../../../themes/styles/hideScrollBar';
import { MD_LAYOUT_BREAKPOINT } from '../../../themes/GlobalThemeProvider';
import { colorPalette } from 'ri-components';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useHttpRequest from '../../../hooks/useHttpRequest';
import SendBoxInputContext from '../SendBoxInput/SendBoxInputContext';
import { Autocomplete } from '@material-ui/lab';
import AutocompletePopper from './AutocompletePopper';

const { white, grey } = colorPalette;

const StyledIconButton = withStyles((theme) => ({
  root: {
    height: '32px',
    width: '32px',
    color: white,
    padding: '0px',
    backgroundColor: theme.palette.success.main,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      color: white,
      backgroundColor: '#999b9f',
    },
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}))(IconButton);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.25, 3, 2.5, 3),
    [theme.breakpoints.up(MD_LAYOUT_BREAKPOINT)]: {
      padding: theme.spacing(2.5, 3),
    },
  },
  text: {
    borderRadius: '10px',
  },
  icon: {
    marginRight: '4px',
    color: grey[10],
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  sendBox: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '24px',
    padding: theme.spacing(0.875, 1, 0.875, 2),
    border: 'solid 1px #dadce1',
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
    },
  },
  sendBoxDefaultBackgroundColor: {
    backgroundColor: white,
  },
  sendBoxDisabledBackgroundColor: {
    backgroundColor: '#f2f3f4',
  },
  sendBoxInput: {
    padding: '10px 10px 10px 0px',
    width: '98%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    background: 'transparent',
    minHeight: '20px',
    maxHeight: '120px',
    fontSize: '18px',
    letterSpacing: '-0.2px',
    paddingBottom: '10px',
  },
  autocompleteInput: {
    width: '98%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    background: 'transparent',
    minHeight: '20px',
    maxHeight: '120px',
    fontSize: '18px',
    height: '22px',
    letterSpacing: '-0.2px',
  },
  iconButton: {
    alignSelf: 'flex-end',
  },
  selectedMenuItem: {
    background: '#f2f2f2',
    overflow: 'unset',
    backgroundColor: '#D1D1D1',
    outline: '2px auto black',
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
  listBox: {
    backgroundColor: '#f2f2f2'
  },
  menuItemRoot: {
    background: '#f2f2f2',
    overflow: 'unset',
    '&[aria-selected="true"]': {
      backgroundColor: '#D1D1D1',
    },
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
    '&:focus': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
}));
const { useSendMessageBack } = hooks;

const AutocompleteSelectSendBox = ({ sendBoxContainerRef, lastActivity }) => {
  const classes = useStyles();
  const [itemSelected, setItemSelected] = useState(null);
  const { hideScrollBar } = useHideScrollBarStyles();
  const [suggestionList, setSuggestionList] = useState([]);
  const [placeholder, setPlaceholder] = useState();
  const textareaRef = useRef(null);
  const { sendRequest } = useHttpRequest();
  const sendMessageBack = useSendMessageBack();
  const [sendBoxBackgroundColor, setSendBoxBackgroundColor] = useState(classes.sendBoxDisabledBackgroundColor);
  const { setValue: setInputValue, inputValue } = useContext(SendBoxInputContext);
  const applyData =
    useCallback((data) => {
      setSuggestionList(data);
      setSendBoxBackgroundColor(classes.sendBoxDefaultBackgroundColor);
      setPlaceholder(lastActivity?.selectDataPlaceholder);
    }, [lastActivity?.selectDataPlaceholder, classes.sendBoxDefaultBackgroundColor]);

  const handleSelected = () => {
    if(itemSelected!==null)
    {
      sendMessageBack(itemSelected.value, itemSelected.key, itemSelected.key);
    }
  };

  const handleEnterKeyPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleSelected();
    } else if (e.keyCode === 13 && !e.shiftKey) e.preventDefault();
  };

  useEffect(() => {
    setPlaceholder(lastActivity?.fetchingDataPlaceholder);
    sendRequest(
      {
        url: `/${lastActivity.apiPath}/${lastActivity.optionsFileName}/${lastActivity.conversation.id}`,
        headers: {
          Authorization: 'Bearer ' + lastActivity.token,
        },
      },
      applyData,
    );
  }, [sendRequest, lastActivity, applyData]);
  const onItemSelected = (option) => {
    setItemSelected(option);
  };

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [textareaRef]);

  const onItemSearched = (value) => {
    setInputValue(value);
  };

  return (
    <div className={classes.root} ref={sendBoxContainerRef}>
      <div
        className={clsx(classes.sendBox, sendBoxBackgroundColor)}
        id='send-box'
        style={{ display: 'flex' }}
      >
        <Autocomplete
          classes={{
            option: classes.menuItemRoot,
            listbox: classes.listBox
          }}
          ref={textareaRef}
          style={{
            width: '98%'
          }}
          PopperComponent={AutocompletePopper}
          options={suggestionList}
          filterOptions={((options) =>
            options.filter((option) =>
              option.key.toLowerCase().includes(inputValue.toLowerCase())
            ))
          }
          getOptionLabel={(option) => {
            return option.key;
          }}
          renderInput={(params) => (
            <div
              ref={params.InputProps.ref}>
              <input
                {...params.inputProps}
                disabled={!suggestionList || suggestionList?.length === 0}
                className={clsx(classes.autocompleteInput, hideScrollBar)}
                autoFocus
                tabIndex='0'
                placeholder={placeholder}
                onKeyDown={handleEnterKeyPress}
                value={inputValue}
              />
            </div>
          )}
          inputValue={inputValue}
          onInputChange={(e, value) => onItemSearched(value)}
          onChange={(event, newValue) => onItemSelected(newValue)}
        />
        <StyledIconButton
          onClick={handleSelected}
          className={classes.iconButton}
          disableRipple
          disabled={itemSelected === null}
          aria-label='Send'
          id='send-button'
        >
          <ArrowForwardIcon />
        </StyledIconButton>
      </div>
    </div>
  );
};

AutocompleteSelectSendBox.propTypes = {
  sendBoxContainerRef: PropTypes.object.isRequired,
  lastActivity: PropTypes.any,
};

export default AutocompleteSelectSendBox;
