import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withClientConfigs } from '../services/ClientConfigsContextProvider';
import { defaultWebChatTheme } from './default-webchat-theme';
import { createTheme } from '@material-ui/core';
import loadTenantManifestAssets from '../utils/loadTenantManifestAssets';

const cssId = 'tenantCSS';

const TenantCssProvider = ({ clientConfigs, setTenantTheme, setIsCssApplied }) => {
  useEffect(() => {
    const cssAlreadyApplied = document.getElementById(cssId);

    if (cssAlreadyApplied) {
      return;
    }

    if (clientConfigs?.BrandAssetsUrl) {
      loadTenantManifestAssets(clientConfigs);
      
      const cssElement = addCssFromBrandAssets(cssId, clientConfigs.BrandAssetsUrl);
      fillThemeFromCss(cssElement, setTenantTheme);
      if (setIsCssApplied) setIsCssApplied(true);
    }
  }, [clientConfigs, setTenantTheme, setIsCssApplied]);
  return <></>;
};

const addCssFromBrandAssets = (cssId, brandAssetsUrl) => {
  const head = document.getElementsByTagName('head')[0];
  const linkElement = document.createElement('link');

  linkElement.id = cssId;
  linkElement.rel = 'stylesheet';
  linkElement.type = 'text/css';
  linkElement.href = `${brandAssetsUrl}/styles.css`;
  linkElement.media = 'all';
  head.appendChild(linkElement);
  return linkElement;
};

const fillThemeFromCss = (cssElement, setTenantTheme) => {
  cssElement.onload = () => {
    const styles = getComputedStyle(cssElement);

    const getStyleVariable = (name) => {
      const result = styles.getPropertyValue(name);
      if (result) return result.trim();
      return null;
    };

    const tenantTheme = createTheme(defaultWebChatTheme, {
      palette: {
        primary: {
          main: getStyleVariable('--primary-main-color'),
        },
      },
      message: {
        user: {
          backgroundColor: getStyleVariable('--user-background-color'),
          color: getStyleVariable('--user-color'),
        },
        bot: {
          backgroundColor: getStyleVariable('--bot-background-color'),
          color: getStyleVariable('--bot-color'),
          typingPointsBackgroundColor: getStyleVariable('--typing-points-background-color'),
        },
        hyperlinkColor: getStyleVariable('--hyperlink-color'),
      },
    });

    if (setTenantTheme) {
      setTenantTheme(tenantTheme);
    }

    defaultWebChatTheme.palette.primary.main =
      getStyleVariable('--primary-main-color') ?? defaultWebChatTheme.palette.primary.main;
    defaultWebChatTheme.message.user.backgroundColor =
      getStyleVariable('--user-background-color') ?? defaultWebChatTheme.message.user.backgroundColor;
    defaultWebChatTheme.message.user.color =
      getStyleVariable('--user-color') ?? defaultWebChatTheme.message.user.color;
    defaultWebChatTheme.message.bot.backgroundColor =
      getStyleVariable('--bot-background-color') ?? defaultWebChatTheme.message.bot.backgroundColor;
    defaultWebChatTheme.message.bot.color =
      getStyleVariable('--bot-color') ?? defaultWebChatTheme.message.bot.color;
    defaultWebChatTheme.message.bot.typingPointsBackgroundColor =
      getStyleVariable('--typing-points-background-color') ??
      defaultWebChatTheme.message.bot.typingPointsBackgroundColor;
    defaultWebChatTheme.message.hyperlinkColor =
      getStyleVariable('--hyperlink-color') ?? defaultWebChatTheme.message.hyperlinkColor;
  };
};

TenantCssProvider.propTypes = {
  clientConfigs: PropTypes.shape({
    BrandAssetsUrl: PropTypes.string,
    ClientAssetsUrl: PropTypes.string,
  }),
  setTenantTheme: PropTypes.func,
  setIsCssApplied: PropTypes.func,
};
export default withClientConfigs(TenantCssProvider);
