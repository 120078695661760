import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { hooks } from 'botframework-webchat-component';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import useHideScrollBarStyles from '../../../themes/styles/hideScrollBar';
import { MD_LAYOUT_BREAKPOINT } from '../../../themes/GlobalThemeProvider';
import { colorPalette } from 'ri-components';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useHttpRequest from '../../../hooks/useHttpRequest';
import SendBoxInputContext from '../SendBoxInput/SendBoxInputContext';

const { white, grey } = colorPalette;

const StyledIconButton = withStyles((theme) => ({
  root: {
    height: '32px',
    width: '32px',
    color: white,
    padding: '0px',
    backgroundColor: theme.palette.success.main,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      color: white,
      backgroundColor: '#999b9f',
    },
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}))(IconButton);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.25, 3, 2.5, 3),
    [theme.breakpoints.up(MD_LAYOUT_BREAKPOINT)]: {
      padding: theme.spacing(2.5, 3),
    },
  },
  text: {
    borderRadius: '10px',
  },
  icon: {
    marginRight: '4px',
    color: grey[10],
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  sendBox: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '24px',
    padding: theme.spacing(0.875, 1, 0.875, 2),
    border: 'solid 1px #dadce1',
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
    },
  },
  displayedPlaceholder: {
    fontFamily: 'inherit',
    fontStyle: 'normal',
    color: 'gray'
  },
  displayedSelectedValue: {
    fontFamily: 'inherit',
    fontStyle: 'normal',
    color: 'black'
  },
  sendBoxDefaultBackgroundColor: {
    backgroundColor: white,
  },
  sendBoxDisabledBackgroundColor: {
    backgroundColor: '#f2f3f4',
  },
  sendBoxInput: {
    '@media (max-width: 420px)': {
      maxWidth: '89%',
    },
    padding: '10px 10px 10px 0px',
    width: '98%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    background: 'transparent',
    minHeight: '20px',
    maxHeight: '120px',
    fontSize: '18px',
    letterSpacing: '-0.2px',
  },
  iconButton: {
    alignSelf: 'flex-end',
  },
  selectedMenuItem: {
    background: '#f2f2f2',
    overflow: 'unset',
    backgroundColor: '#D1D1D1',
    outline: '2px auto black',
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
  menuItemRoot: {
    background: '#f2f2f2',
    overflow: 'unset',
    '&[aria-selected="true"]': {
      backgroundColor: '#D1D1D1',
    },
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
    '&:focus': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
}));
const { useSendMessageBack } = hooks;

const SelectSendBox = ({ sendBoxContainerRef, lastActivity }) => {
  const classes = useStyles();
  const { hideScrollBar } = useHideScrollBarStyles();
  const [selectOptions, setSelectOptions] = useState([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState();
  const textareaRef = useRef(null);
  const { sendRequest } = useHttpRequest();
  const sendMessageBack = useSendMessageBack();
  const [sendBoxBackgroundColor, setSendBoxBackgroundColor] = useState(classes.sendBoxDisabledBackgroundColor);
  const { setValue: setInputValue, inputValue } = useContext(SendBoxInputContext);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        if (inputValue !== '') {
          var selectedOption = selectOptions.find(x => x.key == inputValue);
          sendMessageBack(selectedOption.value, selectedOption.key, selectedOption.key);
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [inputValue, selectOptions, sendMessageBack]);

  const applyData =
    useCallback((data) => {
      setSelectOptions(data);
      setSendBoxBackgroundColor(classes.sendBoxDefaultBackgroundColor);
      setPlaceholder(lastActivity?.selectDataPlaceholder);
    }, [lastActivity?.selectDataPlaceholder, classes.sendBoxDefaultBackgroundColor]);

  const handleSelected = () => {
    if (inputValue !== '') {
      var selectedOption = selectOptions.find(x => x.key == inputValue);
      sendMessageBack(selectedOption.value, selectedOption.key, selectedOption.key);
    }
  }

  useEffect(() => {
    setPlaceholder(lastActivity?.fetchingDataPlaceholder);
    sendRequest(
      {
        url: `/${lastActivity.apiPath}/${lastActivity.optionsFileName}/${lastActivity.conversation.id}`,
        headers: {
          Authorization: 'Bearer ' + lastActivity.token,
        },
      },
      applyData,
    );
  }, [sendRequest, lastActivity, applyData]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [textareaRef]);

  return (
    <div className={classes.root} ref={sendBoxContainerRef}>
      <div
        className={clsx(classes.sendBox, sendBoxBackgroundColor)}
        id='send-box'
        style={{ display: 'flex' }}
      >
        <Select
          onClick={() => (selectOptions && selectOptions.length> 0) && setIsDropDownOpen(!isDropDownOpen)}
          ref={textareaRef}
          classes={{
            root: classes.selectRoot,
          }}
          className={clsx(classes.sendBoxInput, hideScrollBar)}
          displayEmpty
          open={isDropDownOpen}
          onChange={(event) => handleChange(event)}
          value={inputValue}
          renderValue={(selected) => {
            if (selected?.length === 0 || selected === undefined) {
              return <em
                className={classes.displayedPlaceholder}
              >{placeholder}</em>;
            }
            return <em className={classes.displayedSelectedValue}> {selected} </em>
          }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            PaperProps: {
              style: {
                marginTop: -9.5,
                maxHeight: 350,
                borderRadius: '6px',
                background: '#f2f2f2'
              },
            },
          }}
          disableUnderline
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {(selectOptions && selectOptions.length > 0) &&
            selectOptions.map((option) => (
              <MenuItem
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selectedMenuItem,
                }}
                key={option.key}
                value={option.key}>
                {option.key}
              </MenuItem>
            ))}
        </Select>
        <StyledIconButton
          onClick={handleSelected}
          className={classes.iconButton}
          disableRipple
          disabled={inputValue === ''}
          aria-label='Send'
          id='send-button'
        >
          <ArrowForwardIcon />
        </StyledIconButton>
      </div>
    </div>
  );
};

SelectSendBox.propTypes = {
  sendBoxContainerRef: PropTypes.object.isRequired,
  lastActivity: PropTypes.any,
};

export default SelectSendBox;
