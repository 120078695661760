import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

function RouteWrapper({ children, ...other }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Route {...other}>
          {children}
      </Route>
    </ErrorBoundary>
  );
}

RouteWrapper.propTypes = {
  children: PropTypes.node,
};

export default RouteWrapper;
