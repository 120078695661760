export default async function loadTenantManifestAssets(clientConfigs) {
  const head = document.querySelector('head');
  setTabTitle(clientConfigs.TabTitle);
  const favIcon = createTenantFavIcon(clientConfigs.BrandAssetsUrl);
  const manifest = createTenantManifest(clientConfigs.BrandAssetsUrl);
  head.append(manifest, favIcon);
}

function createTenantManifest(brandAssetsUrl) {
  const link = document.createElement('link');
  link.href = `${brandAssetsUrl}/manifest.json`;
  link.rel = 'manifest';
  link.crossOrigin = 'use-credentials';
  return link;
}

function createTenantFavIcon(brandAssetsUrl) {
  const link = document.createElement('link');
  link.href = `${brandAssetsUrl}/favicon.ico`;
  link.rel = 'icon';
  return link;
}

async function setTabTitle(tabTitle) {
  const title = document.querySelector('title');
  title.innerHTML = tabTitle ? tabTitle : 'RightIndem.FNOL';
}
