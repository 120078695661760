import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import ReactPlayer from 'react-player';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardActionList: {
    textAlign: 'center',
    listStyleType: 'none',
    paddingInlineStart: 0,
    margin: theme.spacing(1, 3, 0),
  },
  shoMoreLessListItem: {
    padding: theme.spacing(1.25),
  },
  showMoreLessBtn: {
    color: '#008dff',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  buttonStyle: {
    marginBottom: theme.spacing(1),
    '&:focus': {
      outline: 'none',
    },
  },
  mapModalOverlay: {
    padding: '24px',
    maxWidth: ({ modalWidth }) => modalWidth,
    maxHeight: ({ modalHeight }) => modalHeight,
  },
}));

const InformationalModal = ({
  openInformationalOverlay,
  setOpenInformationalOverlay,
  hasVideo,
  videoUrl,
  autoPlay,
  showControls,
  subtitlesUrl,
  showSubtitles,
  hasHTMLContent,
  htmlContent,
  modalTitle,
  modalWidth,
  modalHeight,
}) => {
  const classes = useStyles({ modalWidth }, { modalHeight });
  const [hasVideoPlayerError, setVideoPlayerHasError] = useState(false);
  const [isReady, setIsReady] = useState(false);

  return (
    <ModalOverlay
      className={classes.mapModalOverlay}
      open={openInformationalOverlay}
      onClose={() => {
        setOpenInformationalOverlay(false);
        setVideoPlayerHasError(false);
        setIsReady(false);
      }}
    >
      <ModalOverlayTitle style={{ marginBottom: '-28px' }}>{modalTitle}</ModalOverlayTitle>
      <ModalOverlayBody>
        {hasVideo === true && (
          <div style={{ display: !isReady ? 'none' : 'flex', justifyContent: 'center' }}>
            <ReactPlayer
              url={videoUrl}
              playing={autoPlay}
              controls={showControls}
              config={{
                file: {
                  attributes: {
                    crossOrigin: 'anonymous',
                  },
                  tracks: [
                    {
                      kind: 'subtitles',
                      src: subtitlesUrl,
                      srcLang: 'en',
                      default: showSubtitles,
                    },
                  ],
                },
              }}
              height='100%'
              width='100%'
              onReady={() => {
                if (!hasVideoPlayerError) {
                  setIsReady(true);
                }
              }}
              onError={() => {
                setVideoPlayerHasError(true);
              }}
            />
          </div>
        )}
        {hasVideo === true && hasHTMLContent === true && !isReady && (
          <Divider style={{ color: 'black', marginBottom: '3px' }} />
        )}
        {hasHTMLContent === true && (
          <span style={{ marginTop: '7px' }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
        )}
      </ModalOverlayBody>
    </ModalOverlay>
  );
};

export default InformationalModal;

InformationalModal.propTypes = {
  openInformationalOverlay: PropTypes.bool,
  setOpenInformationalOverlay: PropTypes.any,
  hasVideo: PropTypes.any,
  hasHTMLContent: PropTypes.any,
  htmlContent: PropTypes.any,
  videoUrl: PropTypes.any,
  clientConfigs: PropTypes.shape({
    ClientAssetsUrl: PropTypes.string,
  }),
  autoPlay: PropTypes.bool,
  showControls: PropTypes.bool,
  subtitlesUrl: PropTypes.string,
  showSubtitles: PropTypes.bool,
  modalTitle: PropTypes.any,
  modalWidth: PropTypes.any,
  modalHeight: PropTypes.any,
};
