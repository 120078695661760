import React  from 'react';
import SendBox from './SendBox'
import PropTypes from 'prop-types';
import useLastActivity from '../hooks/useLastActivity';
import SelectSendBox from './SelectSendBox';
import AutocompleteSelectSendBox from './AutocompleteSelectSendBox';

const InputSwitcher = ({ sendBoxContainerRef }) => {
  const lastActivity = useLastActivity();
    switch (lastActivity?.inputType) {
        case 'simpleSelect':
          return <SelectSendBox sendBoxContainerRef={sendBoxContainerRef} lastActivity= {lastActivity}/>
          case 'autocompleteSelect':
            return <AutocompleteSelectSendBox sendBoxContainerRef={sendBoxContainerRef} lastActivity= {lastActivity}/>
        default:
          return <SendBox sendBoxContainerRef={sendBoxContainerRef}/>;
      }
};

InputSwitcher.propTypes = {
  sendBoxContainerRef: PropTypes.object.isRequired,
};

export default InputSwitcher;
