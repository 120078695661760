import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withClientConfigs } from '../../../services/ClientConfigsContextProvider';

const useStyles = makeStyles((theme) => ({
  avatar: {
    alignSelf: 'center',
    marginRight: theme.spacing(0.75),
    height: 30,
    borderRadius: '20px',
    width: 30,
  },
}));

const BotAvatar = ({ clientConfigs }) => {
  const classes = useStyles();

  return clientConfigs?.BrandAssetsUrl ? (
    <img
      className={classes.avatar}
      src={`${clientConfigs.BrandAssetsUrl}/avatar.jpg`}
      alt='avatar-profile'
    />
  ) : (
    <></>
  );
};

BotAvatar.propTypes = {
  clientConfigs: PropTypes.shape({
    Id: PropTypes.string,
    BrandAssetsUrl: PropTypes.string,
  }),
};

export default withClientConfigs(BotAvatar);
