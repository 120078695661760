import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withClientConfigs } from '../../services/ClientConfigsContextProvider';

const useStyles = makeStyles((theme) => ({
  defaultHeader: {
    position: 'fixed',
    display: 'flex',
    top: '0',
    left: '0',
    // minWidth: '244px',
    // minHeight: '149px',
    [theme.breakpoints.between(0, 1280)]: {
      display: 'none',
    },
  },
}));

const HeaderImage = ({ clientConfigs }) => {
  const classes = useStyles();
  const brandAssetsUrl = clientConfigs?.BrandAssetsUrl;
  const headerImageRedirectUrl = clientConfigs?.HeaderImageRedirectUrl;

  return (
    <>
      {(brandAssetsUrl && headerImageRedirectUrl) ? (
        <div className={classes.defaultHeader + ' header-image'}>
          <a href={headerImageRedirectUrl} target='_blank' rel='noreferrer'>
            <img className='headerImage' src={`${brandAssetsUrl}/header-image.png`} />
          </a>
        </div>
      ) : null}
    </>
  );
};

HeaderImage.propTypes = {
  clientConfigs: PropTypes.shape({
    BrandAssetsUrl: PropTypes.string,
    HeaderImageRedirectUrl: PropTypes.string,
  }),
};

export default withClientConfigs(HeaderImage);
