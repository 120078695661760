import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Components } from 'botframework-webchat-component';
import { createDirectLine } from 'botframework-webchat';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { defaultWebChatTheme } from '../../themes/default-webchat-theme';
import TenantCssProvider from '../../themes/TenantCssProvider';
import WebChat from './WebChat';
import markdownIt from 'markdown-it';
import mdAttrs from 'markdown-it-attrs';
import mdLinkAttributes from 'markdown-it-link-attributes';
import { colorPalette } from 'ri-components';
import { BoxLoading } from '../../components/LoadingSuspense';
import ClientConfigsContextProvider from '../../services/ClientConfigsContextProvider';
import LanguageDataProvider from '../../services/LanguageDataProvider';
import { DirectLineContext } from '../../services/DirectLineContext';
import HeaderImage from './HeaderImage';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ClaimFileContextProvider from '../../services/ClaimFileContextProvider';
import { ClaimStatusProvider } from '../../services/ClaimStatusContextProvider';

const { white, coolGrey } = colorPalette;

const md = markdownIt({ html: true });

md.use(mdAttrs);
md.use(mdLinkAttributes, {
  attrs: {
    target: '_blank',
    rel: 'noopener',
  },
});

export const WEB_CHAT_VERTICAL_MARGIN = 40;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: coolGrey[4],
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  webchatPanel: {
    position: 'relative',
    backgroundColor: white,
    height: '100%',
    width: '100%',
    minWidth: '200px',
    boxShadow: '0 0 24px rgba(161, 171, 197, 0.3)',
    [theme.breakpoints.up('md')]: {
      borderRadius: '12px',
      maxWidth: '760px',
      margin: `${WEB_CHAT_VERTICAL_MARGIN}px auto`,
    },
  },
  loading: {
    margin: theme.spacing(2.5),
  },
}));

const RightIndemWebChat = ({ lineOfBusiness, isAuthJourney }) => {
  const classes = useStyles();
  const [directLine, setDirectLine] = useState();
  const history = useHistory();
  const { userIdentifier, originalConversationId, witnessIdentifier } = useParams();
  Cookies.remove('isResumeJourney', { secure: true, sameSite: 'Strict' });
  
  async function isTenantAuteticationRequired() {
    const response = await fetch('api/app-config/client-auth');
    return await response.json();
  }

  useEffect(() => {
    if (!directLine) {
      isTenantAuteticationRequired().then((res) => {
        if (res.isAuthenticationRequired) {
          const jwtToken = Cookies.get(res.cookieName);
          const sessionId = Cookies.get(res.sessionIDName);

          axios
            .post(`api/bot-config/auth-token`, { token: jwtToken, sessionId: sessionId })
            .then((res) => {
              return res;
            })
            .then((res) => res.data.token)
            .then((token) => setDirectLine(createDirectLine({ token })))
            .catch(() => {
              history.push('/unauthorized');
            });
        } else if (userIdentifier !== undefined) {
          axios
            .post(`api/bot-config/userIdentifier/${userIdentifier}`)
            .then((res) => {
              return res;
            })
            .then((res) => res.data.token)
            .then((token) => setDirectLine(createDirectLine({ token })));
        } else if (isAuthJourney === true) {
          history.push('/motor');
        } else if (originalConversationId && witnessIdentifier) {
          axios
            .post(`api/bot-config/sub-journey/${originalConversationId}/${witnessIdentifier}`)
            .then((res) => {
              return res;
            })
            .then((res) => res.data.token)
            .then((token) => setDirectLine(createDirectLine({ token })));
        } else {
          axios
            .post('api/bot-config/token')
            .then((res) => {
              return res;
            })
            .then((res) => res.data.token)
            .then((token) => setDirectLine(createDirectLine({ token })));
        }
      });
    }
  }, [directLine, userIdentifier, history, isAuthJourney, originalConversationId, witnessIdentifier]);

  return (
    <ThemeProvider theme={defaultWebChatTheme}>
      <div className={classes.root} id='chat-background-outside'>
        <div className={classes.webchatPanel}>
          {directLine ? (
            <Components.Composer directLine={directLine} renderMarkdown={md.render.bind(md)}>
              <ClaimStatusProvider>
                <ClientConfigsContextProvider>
                  {/* <TenantAuthentication/> */}
                  <TenantCssProvider />
                  <LanguageDataProvider>
                    <ClaimFileContextProvider>
                      <DirectLineContext.Provider value={{ directLine, setDirectLine }}>
                        <HeaderImage />
                        <WebChat lineOfBusiness={lineOfBusiness} />
                      </DirectLineContext.Provider>
                    </ClaimFileContextProvider>
                  </LanguageDataProvider>
                </ClientConfigsContextProvider>
              </ClaimStatusProvider>
            </Components.Composer>
          ) : (
            <BoxLoading />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default RightIndemWebChat;

RightIndemWebChat.propTypes = {
  lineOfBusiness: PropTypes.oneOf(['Home', 'Motor']),
  isAuthJourney: PropTypes.any,
};
