import { Popper } from "@material-ui/core";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    autocompletePopper: {
       paddingBottom: '12px',
    },
}));
const AutocompletePopper = (props) => {
    const classes = useStyles();
    return <Popper {...props} placement="top" className={classes.autocompletePopper}  />;
  };

  export default AutocompletePopper;